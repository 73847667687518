li.page-item a.selected-page {
  background-color: #9c27b0;
  border-color: #9c27b0;
  color: #fff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14),
    0 1px 10px 0 rgba(156, 39, 176, 0.12),
    0 2px 4px -1px rgba(156, 39, 176, 0.2);
  display: flex;
  justify-content: center;
}

.order {
  cursor: pointer;
}

li.page-item a {
  display: flex;
  border-radius: 30px !important;
  transition: all 0.3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent;
  background-color: transparent;
  text-align: center;
  color: #999;
}

label.bmd-label-floating {
  color: #3c4858;
  font-size: 15px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  /* background-color: #fcfcfc; */
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgb(251, 251, 251);
}
select.mat-input-element:disabled,
input.mat-input-element:disabled,
.readonly-label,
.readonly-text {
  color: #555 !important;
  opacity: initial;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label,
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #767676 !important;
}

.readonly-label,
.readonly-text {
  font-weight: 400;
  line-height: 1.5;
}
/* .mat-form-field-disabled{
  background-color: rgb(251, 251, 251);
} */
/* textarea.form-control {
  border: solid 0.2px #e3e2e2;
  border-bottom: 0;
}
textarea.form-control:focus {
  border: solid 0.2px purple;
  border-bottom: 0;
} */
textarea.form-control:read-only {
  border: 0;
}

.filter-button {
  width: 45px;
  cursor: pointer;
  color: rgba(163, 0, 255, 0.96);
}
.filter-button:hover {
  cursor: pointer;
  color: purple;
}
@media (min-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
  .grid-container-one {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}
.sebm-google-map-container {
  height: 300px;
}
#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 70px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.space-70 {
  height: 70px;
  display: block;
}

.tim-row {
  margin-bottom: 20px;
}

.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
}
.tim-typo .tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row {
  padding-top: 50px;
}
.tim-row h3 {
  margin-top: 0;
}
@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}
html,
body,
#fullheight {
  min-height: 100% !important;
  height: 100%;
}
button.primary-button {
  background-color: var(--main-color);
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 1rem;
  border: none;
  cursor: pointer;
  color: white;
}

button.primary-button:hover {
  background-color: #7db2d2;
}

.container-width {
  width: 100%;
}
.flex-container {
  display: flex;
  justify-content: center;
}
.container-width-responsive {
  width: 100%;
}

@media (min-width: 768px) {
  .container-width {
    width: 75%;
  }
  .container-width-responsive {
    width: 50%;
  }
}
