:root {
  --ha-primary-color: #9c27b0;
  --ha-primary-contrast-color: #9b27b050;
  --ha-primary-color-hover: #7d1f8e;
  --ha-primary-bg-color: #f8e9fe;
  --ha-box-shadow-primary: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033,
    0 1px 5px #9c27b01f;
  --ha-box-shadow-primary-hover: 0 14px 26px -12px #9c27b06b,
    0 4px 23px #0000001f, 0 8px 10px -5px #9c27b033;
}

/* :root {
    --ha-primary-color: #3cb027;
    --ha-primary-contrast-color: #3cb02750;
    --ha-primary-color-hover: #2c861c;
    --ha-primary-bg-color: #e6f5e5;
    --ha-box-shadow-primary: 0 2px 2px #3cb02724, 0 3px 1px -2px #3cb02733, 0 1px 5px #3cb0271f;
    --ha-box-shadow-primary-hover: 0 14px 26px -12px #3cb0276b, 0 4px 23px #0000001f, 0 8px 10px -5px #3cb02733;
} */

/* Estilos propios */

/* btn-select-options */
.gts-btn-select-primary {
  background-color: var(--ha-primary-bg-color);
  color: var(--ha-primary-color);
  border: 1px solid var(--ha-primary-color);
  border-radius: 4px;
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: auto;
}

.gts-btn-select-primary:disabled {
  background-color: #eeeeee;
  box-shadow: none;
  color: #999999;
  border: none;
}

.gts-btn-select-primary:hover {
  background-color: var(--ha-primary-bg-color);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-select-primary-outline {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  height: 36px;
  /* box-shadow: var(--ha-box-shadow-primary); */
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: auto;
}

.gts-btn-select-primary-outline:disabled {
  background-color: #ffffff;
  border: 1px solid #999999;
  box-shadow: none;
  color: #999999;
}

.gts-btn-select-primary-outlinea:hover {
  /* background-color: var(--ha-primary-bg-color); */
  /* box-shadow: var(--ha-box-shadow-primary-hover); */
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-select-option-primary {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  border: none;
  border-radius: 4px;
  height: 36px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: auto;
}

.gts-btn-select-option-primary:hover {
  background-color: var(--ha-primary-bg-color);
}

.gts-btn-select-option-primary-select {
  background-color: var(--ha-primary-bg-color);
  color: var(--ha-primary-color);
  border: none;
  border-radius: 4px;
  height: 36px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: auto;
}

.gts-btn-select-option-primary-select:hover {
  background-color: var(--ha-primary-bg-color);
}

/* ************ */

.gts-btn-select-option-primary-outline {
  background-color: #ffffff;
  /* color: var(--ha-primary-color); */
  border: none;
  border-radius: 4px;
  height: 36px;
  /* padding: 0px 16px 0px 0px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  min-width: auto;
}

.gts-btn-select-option-primary-outline:hover {
  background-color: var(--ha-primary-bg-color);
}

.gts-btn-select-option-primary-select-outline {
  background-color: var(--ha-primary-bg-color);
  /* color: var(--ha-primary-color); */
  border: none;
  border-radius: 4px;
  height: 36px;
  /* padding: 0px 16px 0px 0px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  min-width: auto;
}

.gts-btn-select-option-primary-select-outline:hover {
  background-color: var(--ha-primary-bg-color);
}

/* BTN primary */

.gts-btn-select-icon {
  font-family: "Material Icons" !important;
  font-size: 18px !important;
  padding-left: 16px !important;
}

.gts-btn-icon {
  font-family: "Material Icons" !important;
  font-size: 18px !important;
}

.gts-btn-icon-left {
  font-family: "Material Icons" !important;
  font-size: 18px !important;
  padding-right: 8px !important;
}
.gts-btn-icon-right {
  font-family: "Material Icons" !important;
  font-size: 18px !important;
  padding-left: 8px !important;
}
.gts-btn-text {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  white-space: nowrap;
}
.gts-btn-text-uppercase {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  white-space: nowrap;
}

/* .gts-btn-primary  */
.gts-btn-primary {
  background-color: var(--ha-primary-color);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  transition: transform 0.4s;
}

.gts-btn-primary:disabled {
  background-color: #eeeeee;
  box-shadow: none;
  color: #999999;
}

.gts-btn-primary:hover {
  background-color: var(--ha-primary-color-hover);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-primary:active {
  transform: scale(0.96) !important;
}

/* .gts-btn-primary-outline */

.gts-btn-primary-outline {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  border: 1px solid var(--ha-primary-color);
  border-radius: 4px;
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  transition: transform 0.4s;
}
.gts-btn-primary-outline:disabled {
  background-color: #eeeeee;
  box-shadow: none;
  color: #999999;
  border: none;
}

.gts-btn-primary-outline:hover {
  background-color: var(--ha-primary-bg-color);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-primary-outline:active {
  transform: scale(0.96) !important;
}

/* .gts-btn-primary-basic */

.gts-btn-primary-basic {
  background-color: #ffffff00;
  color: var(--ha-primary-color);
  border: none;
  border-radius: 4px;
  height: 36px;
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  transition: transform 0.4s;
}
.gts-btn-primary-basic:disabled {
  background-color: #eeeeee;
  box-shadow: none;
  color: #999999;
  border: none;
}

.gts-btn-primary-basic:hover {
  background-color: var(--ha-primary-bg-color);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-primary-basic:active {
  transform: scale(0.96) !important;
}

/* .gts-btn-primary-only-icon */

.gts-btn-primary-only-icon {
  background-color: #ffffff00;
  color: #000;
  border: none;
  border-radius: 20px;
  height: 36px;
  width: 36px;

  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  /* padding: 0px 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  transition: transform 0.4s;
}
.gts-btn-primary-only-icon:disabled {
  background-color: #eeeeee;
  box-shadow: none;
  color: #999999;
  border: none;
}

.gts-btn-primary-only-icon:hover {
  background-color: #dadada;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-primary-only-icon:active {
  transform: scale(0.96) !important;
}

/* title-actions */

.title-actions {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  border: 1px solid var(--ha-primary-color);
  border-radius: 4px 0px 0px 4px;
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
}

.gts-btn-primary-outline-actions-active {
  background-color: var(--ha-primary-bg-color) !important;
}

.gts-btn-primary-outline-actions {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  padding: 0px 16px;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  min-width: auto;
  border-bottom: 1px solid var(--ha-primary-color);
  border-top: 1px solid var(--ha-primary-color);
  border-left: none;
  border-right: 1px solid var(--ha-primary-color);
  /* margin-left: -3px; */
}

.gts-btn-primary-outline-actions-end {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  min-width: auto;
  border-radius: 0px 4px 4px 0px;
  border-bottom: 1px solid var(--ha-primary-color);
  border-top: 1px solid var(--ha-primary-color);
  border-left: none;
  border-right: 1px solid var(--ha-primary-color);
  /* margin-left: -3px; */
}

.dt-buttons button {
  margin-left: -3px !important;
}

.gts-btn-primary-outline-actions-start {
  background-color: #ffffff;
  color: var(--ha-primary-color);
  height: 36px;
  box-shadow: var(--ha-box-shadow-primary);
  /* box-shadow: 0 2px 2px #9c27b024, 0 3px 1px -2px #9c27b033, 0 1px 5px #9c27b01f; */
  padding: 0px 16px;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  min-width: auto;
  border-radius: 4px 0px 0px 4px;
  border-bottom: 1px solid var(--ha-primary-color);
  border-top: 1px solid var(--ha-primary-color);
  border-left: 1px solid var(--ha-primary-color);
  border-right: 1px solid var(--ha-primary-color);
}

.gts-btn-primary-outline-actions:hover {
  background-color: var(--ha-primary-bg-color);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.gts-btn-primary-outline-actions-end:hover {
  background-color: var(--ha-primary-bg-color);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.gts-btn-primary-outline-actions-start:hover {
  background-color: var(--ha-primary-bg-color);
  box-shadow: var(--ha-box-shadow-primary-hover);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.gts-btn-primary-outline-actions:active {
  transform: scale(0.96) !important;
  /* border-left: 1px solid var(--ha-primary-color); */
}

.gts-btn-primary-outline-actions-end:active {
  transform: scale(0.96) !important;
}

.gts-btn-primary-outline-actions-start:active {
  transform: scale(0.96) !important;
}

.ha-badge-basic {
  background-color: var(--ha-primary-color);
  color: #fff;
  border-radius: 20px;
  padding: 0px 6px;
}

#special-autocomplete .mat-autocomplete-panel {
  z-index: 1100 !important; /* Valor suficientemente alto para que esté sobre el modal */
}
/* LAYOUD */

.container-buttons-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 16px;
}

/* Styles Text, titles, etc */

.itad-card-title-2 {
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000 !important;
  margin: 0;
  padding: 0;
}

/* Sobreescritura de estilos de angular material */

.mdc-form-field > label {
  margin: 0;
  padding: 0;
}

.mdc-form-field {
  margin-right: 16px;
}

/* Sobreescritura de colores de angular material */

/* radio-button */
.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked {
  --mdc-radio-selected-focus-icon-color: var(--ha-primary-color) !important;
  --mdc-radio-selected-hover-icon-color: var(--ha-primary-color) !important;
  --mdc-radio-selected-icon-color: var(--ha-primary-color) !important;
  --mdc-radio-selected-pressed-icon-color: var(--ha-primary-color) !important;
  --mat-mdc-radio-checked-ripple-color: var(--ha-primary-color) !important;
}

/* progress-bar */

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(
    --ha-primary-color
  ) !important;
}

.mat-mdc-slide-toggle.mat-accent {
  padding-top: 6px;
  --mdc-switch-selected-focus-state-layer-color: var(
    --ha-primary-color
  ) !important;
  --mdc-switch-selected-handle-color: var(--ha-primary-color) !important;
  --mdc-switch-selected-hover-state-layer-color: var(
    --ha-primary-color
  ) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(
    --ha-primary-color
  ) !important;
  --mdc-switch-selected-focus-handle-color: var(
    --ha-primary-color-hover
  ) !important;
  --mdc-switch-selected-hover-handle-color: var(
    --ha-primary-color-hover
  ) !important;
  --mdc-switch-selected-pressed-handle-color: var(
    --ha-primary-color-hover
  ) !important;
  --mdc-switch-selected-focus-track-color: var(
    --ha-primary-contrast-color
  ) !important;
  --mdc-switch-selected-hover-track-color: var(
    --ha-primary-contrast-color
  ) !important;
  --mdc-switch-selected-pressed-track-color: var(
    --ha-primary-contrast-color
  ) !important;
  --mdc-switch-selected-track-color: var(
    --ha-primary-contrast-color
  ) !important;
}

/* Sobreescritura de estilos de SweetAlert */
.swal2-actions {
  flex-direction: row-reverse !important;
  gap: 16px !important;
}

/* Input Time with style of material-design */
.itad-mat-form-field {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.itad-mat-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px;
  margin: 0px;
}

.itad-mat-input {
  font-size: 16px;
  padding: 0px 0px 4px 0px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  outline: none;
}

.itad-mat-input:focus {
  border-bottom: 2px solid var(--ha-primary-color); /* Color azul para simular el foco de Angular Material */
}

.itad-badge-inactive {
  background-color: #727272;
}

.itad-badge-active {
  background-color: #00d853;
}

/* .custom-tooltip {
    background-color: #000000ad;
    color: white;
    padding: 16px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
} */
.custom-tooltip {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.animate__faster_100ms {
  --animate-duration: 0.1s;
}

/* ---- padding of select in mat-paginator --- */
mat-paginator .mat-mdc-select-value {
  text-align: center !important;
  padding-right: 4px !important;
}

mat-paginator .mat-mdc-select-arrow svg {
  left: -2px !important;
}

/* ------------------------------------------- */

.skeleton-section {
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  position: relative;
  overflow: hidden;
}

.skeleton-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: -300px;
  height: 100%;
  width: 300px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -300px;
  }
  100% {
    left: 100%;
  }
}
.cdk-overlay-container {
  z-index: 1100 !important; /* Un valor alto para asegurar que esté sobre el modal */
}
